import cx from "classnames";
import { defineMessages, FormattedMessage } from "react-intl";

import Button from "../../../components/ui/button";
import { memo, useCallback, useMemo } from "react";

import { useRequiredInquireData } from "../state";

const Dot = ({ isSelected }) => {
  return (
    <span
      className={cx(
        "block",
        "w-3",
        "h-3",
        "rounded-full",

        isSelected ? "dark:bg-yellow-900" : "dark:bg-gray-800"
      )}
    ></span>
  );
};

const Title = ({ isCompleted }) => {
  const id = isCompleted
    ? "require-inquire:registered"
    : "require-inquire:create-account";
  const defaultMessage = isCompleted
    ? "You are now registered"
    : "Create Account";
  return (
    <h2>
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </h2>
  );
};

const Description = ({ isCompleted }) => {
  const titleId = isCompleted
    ? "require-inquire:completed:welcome-title"
    : "require-inquire:welcome-title";
  const titleDefaultMessage = "Welcome to your HEAT dashboard";

  const descriptionId = isCompleted
    ? "require-inquire:completed:welcome-message"
    : "require-inquire:welcome-message";
  const descriptionDefaultMessage = isCompleted
    ? "Here you can access your personal dashboard check your last session time, see your leaderboard ranking & update your profile."
    : "Please set up your account and tell us a little bit about yourself";
  return (
    <div>
      <h3>
        <FormattedMessage id={titleId} defaultMessage={titleDefaultMessage} />
      </h3>
      <p className="font-normal">
        <FormattedMessage
          id={descriptionId}
          defaultMessage={descriptionDefaultMessage}
        />
      </p>
    </div>
  );
};

export const StaticContent = memo(({ isCompleted }) => (
  <header className="grid grid-rows-3 gap-16 font-bold">
    <div>
      <image src={"assets/heatlogo.png"} height={45} width={45} alt="VRai" />
    </div>

    <Title isCompleted={isCompleted} />
    <Description />
  </header>
));

StaticContent.displayName = "StaticContent";

const Controller = ({ onStepForward }) => {
  const {
    state: { steps, currentStep, hasPrevious, hasNext, fields },
    dispatch,
  } = useRequiredInquireData();

  const handleOnStepFroward = useCallback(() => {
    if (!fields) return;
    return onStepForward(currentStep, fields.sections.length || 0);
  }, [currentStep, onStepForward, steps.length, fields]);

  const isLastStep = currentStep === (fields && fields.sections.length - 1 || 0);

  if (!fields) {
    return null;
  }

  return (
    <div className="max-w-lg grid grid-cols-3 auto-rows-min gap-4">
      {hasPrevious ? (
        <Button type="button" variant="SECONDARY" onClick={() => dispatch({ type: "PREVIOUS" })}>
          <FormattedMessage
            id="require-inquire:previous"
            defaultMessage="previous"
          />
        </Button>
      ) : (
        <div />
      )}
      <ul className="grid auto-cols-min grid-flow-col gap-2 my-4 mx-auto">
        {fields.sections.map((section, index) => (
          <li key={`create-account-dot-${section.code}`}>
            <Dot isSelected={currentStep === index} />
          </li>
        ))}
      </ul>

      <Button type="submit" onClick={handleOnStepFroward}>
        <FormattedMessage
          id={isLastStep ? "require-inquire:finish" : "require-inquire:next"}
          defaultMessage={isLastStep ? "finish" : "next"}
        />
      </Button>
    </div>
  );
};

export default Controller;
