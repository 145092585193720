// TODO: PROVISORY CODE
import client from "../../client";

const API_KEY = "adssfuhiwr3230SDFAsdf";

const OLD_API = "https://app-vrai-heat-dev.azurewebsites.net";

const USER_ID = "df2976a1-f0b5-492f-a57c-1845df53fe39";

export const getUserAggregateTimes = async () => {
  const { data } = await client.get(
    `${OLD_API}/api/User/GetTraineeTimesAggregate?dateStart=2021-7-06T23%3A59%3A59&dateEnd=2021-7-06T23%3A59%3A59&userName=${USER_ID}`,
    {
      headers: { ApiKey: API_KEY },
    }
  );
  return data;
};


export const getUserTypeCount = async () => {
  const { data } = await client.get(`${OLD_API}/api/Instructor/GetUserTypeCount`, {
    headers: { ApiKey: API_KEY },
  });
  return data;
}

export const getUserListByType = async (key) => {
  const { data } = await client.get(`${OLD_API}/api/Instructor/GetProjectTrainneeAggregateData/${key}`, {
    headers: { ApiKey: API_KEY },
  });
  return data;
}

export const getUserAggregateData = async () => {
  const { data } = await client.get(`${OLD_API}/api/Instructor/GetUserAggregateData/asd`, {
    headers: { ApiKey: API_KEY },
  });
  return data;
}

export const getUser = async (id) => {
  const { data } = await client.get(`Person/${id}`, {
    headers: { ApiKey: API_KEY },
  });
  return data;
}

export const getWelcomeSurvey = () => {
  return require('./../../static/forms/idas-survey.json');
}