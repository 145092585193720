import { useSlider, useSliderThumb } from "@react-aria/slider";
import { useSliderState } from "@react-stately/slider";
import { useFocusRing } from "@react-aria/focus";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { mergeProps } from "@react-aria/utils";
import { useNumberFormatter } from "@react-aria/i18n";
import { useRef } from "react";

import { useFormContext } from "react-hook-form";

import cx from "classnames";

import { useMemo } from "react";
import classes from "./percentage-slider.module.css";

const PercentageSlider = (props) => {
  const { watch, register, setValue } = useFormContext();

  const { ref } = useMemo(() => {
    return register(props.name);
  }, [props.name, register]);

  const value = watch(props.name);

  let trackRef = useRef(null);
  let numberFormatter = useNumberFormatter({ style: "percent" });
  let state = useSliderState({
    ...props,
    numberFormatter,
    value,
    onChange: (value) => {
      setValue(props.name, value);
    },
  });
  let { groupProps, trackProps, labelProps } = useSlider(
    props,
    state,
    trackRef
  );

  return (
    <div
      className={"w-full relative flex flex-col items-center"}
      {...groupProps}
      style={{ touchAction: "none" }}
    >
      {/* Create a flex container for the label and output element. */}
      <div className="w-full mb-4">
        {props.label && <label {...labelProps}>{props.label}</label>}
      </div>

      {/* The track element holds the visible track line and the thumb. */}
      <div className={"w-full flex flex-col px-3"}>
        {props.divisions && (
          <div className="w-10/12 flex justify-between mb-2">
            {Array.from(
              { length: props.divisions + 1 },
              (value, index) => (index) / props.maxValue
            ).map((value, i) => (
              <span
                className={cx(classes.marker, "text-gray-600 text-xs")}
                key={`${props.name}-slider-div-${i}`}
              >
                {value * props.divisions}
              </span>
            ))}
          </div>
        )}
        <div className="w-full flex relative">
          <div
            {...trackProps}
            ref={trackRef}
            className={cx(classes.wrapper, "w-10/12 h-8 relative")}
            data-label-before={props.before}
            data-label-after={props.after}
          >
            <div className="w-full absolute bg-yellow-900  rounded top-3 h-1 opacity-40" />
            <div
              className="absolute bg-yellow-900 rounded top-3 h-1"
              style={{ width: state.getThumbValueLabel(0) }}
            />
            <Thumb index={0} state={state} trackRef={trackRef} />
          </div>
          <div className="w-2/12 flex pl-4">
            <span className="flex bg-gray-1000 px-4 py-1 w-full text-sm items-center justify-center">
              {state.getThumbValueLabel(0)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Thumb = (props) => {
  let { state, trackRef, index } = props;
  let inputRef = useRef(null);
  let { thumbProps, inputProps } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef,
    },
    state
  );

  let { focusProps, isFocusVisible } = useFocusRing();

  return (
    <div
      className="absolute"
      style={{
        top: 4,
        transform: "translateX(-50%)",
        left: `${state.getThumbPercent(index) * 100}%`,
      }}
    >
      <div
        {...thumbProps}
        className={cx(
          "rounded-full w-5 h-5 dark:bg-yellow-900 border border-transparent",
          isFocusVisible && "dark:border-white"
        )}
      >
        <VisuallyHidden>
          <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
        </VisuallyHidden>
      </div>
    </div>
  );
};

export { PercentageSlider };
