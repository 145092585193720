import cx from "classnames";
import { FormattedMessage } from "react-intl";

import { memo, useMemo } from "react";

import { useRequiredInquireData } from "../state";

const Dot = ({ isSelected }) => {
  return (
    <span
      className={cx(
        "block",
        "w-3",
        "h-3",
        "rounded-full",

        isSelected ? "dark:bg-yellow-900" : "dark:bg-gray-800"
      )}
    ></span>
  );
};

const Title = ({ isCompleted }) => {
  const id = isCompleted
    ? "require-inquire:registered"
    : "require-inquire:create-account";
  const defaultMessage = isCompleted
    ? "You are now registered"
    : "Create Account";
  return (
    <h2 className="pt-8">
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </h2>
  );
};

const Description = ({ isCompleted }) => {
  const titleId = isCompleted
    ? "require-inquire:completed:welcome-title"
    : "require-inquire:welcome-title";
  const titleDefaultMessage = "Welcome to your HEAT dashboard";

  const descriptionId = isCompleted
    ? "require-inquire:completed:welcome-message"
    : "require-inquire:welcome-message";
  const descriptionDefaultMessage = isCompleted
    ? "Here you can access your personal dashboard check your last session time, see your leaderboard ranking & update your profile."
    : "Please set up your account and tell us a little bit about yourself";
  return (
    <div className="pt-8">
      <h3 className="pb-4">
        <FormattedMessage id={titleId} defaultMessage={titleDefaultMessage} />
      </h3>
      <p className="font-normal">
        <FormattedMessage
          id={descriptionId}
          defaultMessage={descriptionDefaultMessage}
        />
      </p>
    </div>
  );
};

export const StaticContent = memo(({ isCompleted }) => (
  <header className="grid grid-flow-row auto-rows-min gap-16 text-2xl font-bold px-7 lg:px-14 py-5 lg:py-10 dark:bg-gray-900">
    {!isCompleted && <>
      <Title isCompleted={isCompleted} />
      <Description />
    </>}
  </header>
));

StaticContent.displayName = "StaticContent";

export default StaticContent;
