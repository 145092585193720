import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import Button from "../../components/ui/button";
import { useUser } from "../../context/user";

const Options = () => {

  const { dispatch } = useUser();

  const onChange = useCallback((evt) => {
    const value = evt.target.value;
    dispatch({ type: 'SET_ROLE', payload: value });
  }, [dispatch]);

  return <label htmlFor="select-role">
    Role: <select className="text-gray-900 mr-4" id="select-role" onChange={onChange}>
      <option value="instructor">instructor</option>
      <option value="trainee">trainee</option>
    </select>
  </label>
};
const Header = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return <header className="col-span-2 p-4 dark:bg-gray-850 flex justify-between items-center">
    <div className="flex">
      {/*<Options />*/}
      <div className="text-base dark:text-white">
        {isAuthenticated && <Button onClick={() => {
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }}>Sign Out</Button>}
      </div>
    </div>

  </header >
};

export default Header;
