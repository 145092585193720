import { useEffect, useMemo } from "react";
import { useRadioGroup, useRadio } from "@react-aria/radio";
import { useRadioGroupState } from "@react-stately/radio";
import { createContext, forwardRef, useContext, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ReferenceLine } from "recharts";

let RadioContext = createContext(null);

const Radio = ({ name, isFirstInput, autoFocus, triggerRef, required, ...props }) => {
  let state = useContext(RadioContext);
  const { register } = useFormContext();
  const ref = useRef();

  let { inputProps } = useRadio(props, state, ref);

  return (
    <label className="grid grid-cols-[1fr,min-content] gap-1 p-2 items-center cursor-pointer">
      <span className="block">{props.children}</span>
      <input
        {...inputProps}
        name={name}
        ref={(e) => {
          triggerRef(e);
          ref.current = e;
        }}
        autoFocus={autoFocus && (isFirstInput || inputProps.checked)}
        className="block cursor-pointer"
      />
    </label>
  );
};

Radio.displayName = "Radio";

const RadioGroup = forwardRef(({ name, options, autoFocus, required = false, ...props }, outref) => {
  const { label } = props;
  const { watch, setValue, register, formState: { errors } } = useFormContext();
  const value = watch(name);

  const { ref } = useMemo(() => {
    return register(name, { required: required });
  }, [name, register]);

  let state = useRadioGroupState({
    name: name,
    label: label,
    onChange: (value) => {
      setValue(name, value);
    },
    value: value || null,
  });
  let { radioGroupProps, labelProps } = useRadioGroup(props, state);

  return (
    <>
      <div>
        <div {...radioGroupProps} className="flex">
          <span {...labelProps} className="font-medium w-4/12">
            {label}
          </span>
          <div className="w-8/12 flex align-center justify-center">
            <RadioContext.Provider value={state}>
              {options.map(({ value, label }, index) => (
                <Radio
                  key={value}
                  value={value}
                  name={`${name}-${value}`}
                  autoFocus={autoFocus}
                  triggerRef={ref}
                  isFirstInput={index === 0}
                  required={required}
                >
                  {label}
                </Radio>
              ))}
            </RadioContext.Provider>
          </div>
        </div>
        {errors[name]?.type === 'required' && <span className="text-danger text-xs">This field is required.</span>}
      </div>
    </>
  );
});

export { Radio, RadioGroup };
