import cx from "classnames";

import classes from "./modal.module.css";

const Modal = ({ children }) => {
    return (
        <div className={cx("fixed inset-0 flex items-center justify-center", classes.wrapper)}>
            <div className={cx("self-center justify-self-center", classes.container)}>
                {children}
            </div>
        </div>
    );
};

export default Modal;
