import { useListBox, useOption } from "@react-aria/listbox";
import { useRef } from "react";
import cx from "classnames";

import classes from "./list-box.module.css";

function ListBox(props) {
  let ref = useRef();
  let { listBoxRef = ref, state } = props;
  let { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <ul className={cx(classes.list)} {...listBoxProps} ref={listBoxRef}>
      {[...state.collection].map((item) => (
        <Option key={item.key} item={item} state={state} />
      ))}
    </ul>
  );
}

function Option({ item, state }) {
  let ref = useRef();
  let { optionProps, isSelected, isFocused, isDisabled } = useOption(
    { key: item.key },
    state,
    ref
  );

  return (
    <li
      className={cx(
        "p-2 cursor-pointer text-sm",
        isSelected
          ? "bg-yellow-900 hover:bg-yellow-800"
          : "bg-gray-800 hover:bg-gray-900",
        isSelected
          ? "text-gray-900 hover:text-gray-800"
          : "text-gray-500 hover:text-gray-200"
      )}
      {...optionProps}
      ref={ref}
    >
      {item.rendered}
    </li>
  );
}

export { ListBox, Option };
