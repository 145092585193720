import { useReducer, useContext, createContext, useEffect } from 'react'
import { useQuery } from 'react-query';
import { getUser } from '../../api/user';
import { reducer, SET_ROLE } from './reducer';

export const Roles = {
    TRAINEE: 'Trainee',
    INSTRUCTOR: 'Instructor',
    VIEWER: 'Viewer',
};

export const initialState = {
    role: null,
    id: null,
};

const UserContext = createContext();

const UserProvider = ({ id, children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    /*
    useEffect(async () => {
        if (state.id) {
            console.log(state.id);
            const user = await getUser(state.id);
            console.log(user);
        }
    }, [state.id]);*/

    //const { data } = useQuery(["getUser", id], ({ queryKey: [, id] }) => getUser(id));

    return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>
}

function useUser() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}

export { UserProvider, useUser };