import cx from "classnames";

import { RequireInquireProvider, useRequiredInquireData } from "./state";

import StaticContent from "./static-content";
import Controller from "./controller";
import { FormProvider, useForm } from "react-hook-form";

import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Button from "../../components/ui/button";
import { useEffect } from "react";
import Select from "../../components/ui/select";
import { FormattedMessage, useIntl } from "react-intl";
import { RadioGroup } from "../../components/ui/radio";
import Classify from "../../components/ui/classify";
import { PercentageSlider } from "../../components/ui/percentage-slider";
import Input from "../../components/ui/input";
import { BiLoaderAlt } from "react-icons/bi";

const localeFn = (target) => import(`./locale/${target.toLowerCase()}.json`);


const StepperGenerator = ({ section }) => {
  const intl = useIntl();

  if (!section.questions) return null;

  return <>
    {section.questions.map(({ type, code, options, isRequired = false, ...props }) => {

      switch (type) {
        case 'text':
          return <Input
            key={code}
            id={code}
            name={code}
            required={isRequired}
            label={intl.formatMessage({
              id: `${code}`
            })} />
        case 'select':
          return <Select
            key={code}
            id={code}
            name={code}
            required={isRequired}
            label={intl.formatMessage({
              id: `${code}`
            })}
            options={options.map(({ value, code }) => ({
              id: code,
              value,
              label: intl.formatMessage({
                id: `${code}`
              })
            }))}
          />
        case 'radio':
          return <RadioGroup
            key={code}
            name={code}
            required={isRequired}
            options={options.map(({ value, code }) => ({
              value,
              label: intl.formatMessage({ id: code })
            }))}
            label={intl.formatMessage({
              id: `${code}`
            })}
          />
        case 'rate':
          return <Classify
            key={code}
            required={isRequired}
            label={intl.formatMessage({ id: code })}
            name={code}
            from={options[0].value}
            to={options[1].value}
            before={intl.formatMessage({ id: options[0].code })}
            after={intl.formatMessage({ id: options[1].code })}
          />
        case 'percentage-slider':
          return <PercentageSlider
            key={code}
            required={isRequired}
            name={code}
            divisions={props.divisions}
            before={props.fromLabel}
            after={props.toLabel}
            label={intl.formatMessage({ id: code })}
            maxValue={1}
            step={props.step / 100}
          />
      }
      return null;
    })}
  </>;
}

const Form = ({ onComplete = null, ...props }) => {
  const {
    onSubmit,
    form,
    state: { isMobile, status, fields, currentStep },
  } = useRequiredInquireData();

  useEffect(() => {
    if (status === "COMPLETED") {
      if (onComplete) {
        //onComplete();
      }
    }
  }, [status, onComplete]);

  const isPending = status === 'PENDING';
  const isWaiting = status === 'WAITING';
  const isCompleted = status === 'COMPLETED';

  return (
    <FormProvider {...form}>
      {/*isMobile ? <FormMobile {...props} /> : <FormDesktop {...props} />*/}
      <form
        onSubmit={onSubmit}
        className="grid grid-cols-[2fr,3fr] shadow-lg gap-1 h-full dark:text-gray-100 dark:border-black"
      >
        <StaticContent isCompleted={isCompleted} />
        <div className="grid grid-rows-[1fr,min-content] dark:bg-gray-900 p-8 justify-center">
          <section
            className={cx(
              "w-full",
              "dark:bg-gray-900",
              "grid",
              "items-center",
              "relative"
            )}
          >
            {isPending &&
              <div className="flex items-center h-full right-2 top-0">
                <BiLoaderAlt fontSize="4rem" className="animate-spin" />
              </div>}
            {isWaiting &&
              <div className="lg:max-w-lg m-auto w-full grid grid-flow-row lg:p-8 gap-6 grid-cols-[minmax(0,1fr)]">
                {fields && <StepperGenerator form={form} section={fields?.sections[currentStep]} />}
              </div>}
            {isCompleted &&
              <div className="flex flex-col items-center justify-content">
                <h2 className="text-2xl">
                  <FormattedMessage id="require-inquire:survey-completed-title" />
                </h2>
                <p className="mt-8">
                  <FormattedMessage id="require-inquire:survey-thanks-message" />
                </p>
                <Button onClick={onComplete} className="mt-4">
                  <FormattedMessage id="require-inquire:survey-dismiss" />
                </Button>
              </div>}
          </section>
          {isWaiting && <Controller onStepForward={props.onStepForward || null} />}
        </div>
      </form >
    </FormProvider>
  );
};

const CreateAccount = (props) => {
  return <VRIntlProviderComponent localeFn={localeFn} id="require-inquire">
    <RequireInquireProvider>
      <Form {...props} />
    </RequireInquireProvider>
  </VRIntlProviderComponent>
}


export default CreateAccount;
