import jwt_decode from "jwt-decode";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { QueryClient, QueryClientProvider } from "react-query";

import VRIntlSuspender from "./components/providers/intl-provider";
import { UserProvider, useUser } from "./context/user";

import { msalConfig, loginRequest } from "./authConfig";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { SET_ID, SET_ROLE } from "./context/user/reducer";

import Header from "./modules/header";
import Modal from "./components/ui/modal";

import RequiredInquire from "./modules/required-inquire";

const Content = () => {
  const [initialized, setInitialized] = useState(false);
  const [firstUse, setFirstUse] = useState(false);
  const [surveyModalOpened, setSurveyModalOpened] = useState(true);
  const { instance, accounts } = useMsal();
  const { state: user, dispatch: userDispatch } = useUser();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {

      const token = window.localStorage.getItem('accessToken');

      const isFirstUse = window.localStorage.getItem('isFirstUse');

      if (isFirstUse === null) {
        window.localStorage.setItem('isFirstUse', 'true');
        setFirstUse(true);
      } else {
        if (isFirstUse === "true") {
          window.localStorage.setItem('isFirstUse', 'false');
          setFirstUse(false);
        }
      }
    } else {
      instance.loginRedirect(loginRequest).then(() => {
        setInitialized(true);
      }).catch(e => {
        console.error(e);
      });
    }
  }, [isAuthenticated, instance]);

  useEffect(() => {
    if (initialized || accounts) {
      instance.setActiveAccount(accounts[0]);

      instance.acquireTokenSilent({
        ...loginRequest,
      }).then((response) => {
        const { accessToken, uniqueId } = response;

        //var { extension_HeatRoles: role } = jwt_decode(accessToken);

        //userDispatch({ type: SET_ROLE, payload: role });
        userDispatch({ type: SET_ID, payload: uniqueId });

        console.log('VUPLEX: ', window.vuplex);

        if (window.vuplex) {
          window.vuplex.postMessage(`auth_success:${accessToken}`);
        }

        window.localStorage.setItem('accessToken', accessToken);
      })
        .catch((e) => console.log(e));
    }
  }, [accounts, initialized, instance, userDispatch]);

  const onStepForward = useCallback((currentStep, total) => {
    if (currentStep === total - 1) {
      window.localStorage.setItem('isFirstUse', 'false');
    }
    return true;
  }, []);

  const onCompleteSurvey = useCallback(() => {
    setSurveyModalOpened(false);
  }, []);

  if (!user.id) {
    return null;
  }

  return isAuthenticated ?
    <div className="grid grid-cols-[minmax(min-content,200px),1fr] grid-rows-[min-content,1fr] gap-4">
      <Header />
      {surveyModalOpened && <Modal>
        <RequiredInquire onStepForward={onStepForward} onComplete={onCompleteSurvey} />
      </Modal>}
    </div> : null;
};

const msalInstance = new PublicClientApplication(msalConfig);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: 3000,
      suspense: true,
      useErrorBoundary: true,
      refetchOnWindowFocus: false
    }
  }
});

const App = () =>
  <MsalProvider instance={msalInstance}>
    <QueryClientProvider client={client}>
      <VRIntlSuspender>
        <BrowserRouter>
          <UserProvider>
            <Content />
          </UserProvider>
        </BrowserRouter>
      </VRIntlSuspender >
    </QueryClientProvider>
  </MsalProvider>

export default App;