import { useFormContext } from "react-hook-form";
import cx from "classnames";
const Input = ({ label, name, required = false, icon: SelectedIcon, disabled, className = "", ...props }) => {
    const { register, formState: { errors } } = useFormContext();

    return <>
        <div>
            {label}
            <div className={cx(
                className,
                "flex flex-wrap items-stretch w-full relative h-15 bg-gray-850 items-center mb-2 border-b border-yellow-900"
            )}>
                {SelectedIcon &&
                    <div className="flex -mr-px justify-center w-15 p-4">
                        <span className="flex items-center leading-normal bg-gray-850 text-lg px-1 whitespace-no-wrap text-white">
                            <SelectedIcon />
                        </span>
                    </div>
                }

                <input
                    disabled={disabled}
                    {...props}
                    {...register(name, { required })}
                    className={cx(
                        disabled && "opacity-30",
                        "flex-shrink bg-gray-850 flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 px-2 relative self-center text-lg outline-none")}
                />
            </div>
            {errors[name]?.type === 'required' && <span className="text-danger text-xs">This field is required.</span>}
        </div>
    </>
}

export default Input;