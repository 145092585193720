import cx from "classnames";
const Button = ({
  children,
  variant = "PRIMARY",
  size = "normal",
  className,
  disabled,
  ...props
}) => {
  let variantClass = "";
  let sizeClass = "";

  switch (variant) {
    case "SECONDARY":
      variantClass =
        "dark:bg-transparent dark:hover:bg-gray-800 dark:border-yellow-900 dark:hover:border-yellow-800 border dark:active:border-transparent";
      break;
    case "PRIMARY":
    default:
      variantClass =
        "dark:bg-yellow-900 dark:hover:bg-yellow-800 dark:active:bg-yellow-700 dark:text-black border dark:border-transparent";
  }

  switch (size) {
    case "small":
      sizeClass = "font-normal px-2 py-1";
      break;
    case "normal":
    default:
      sizeClass = "font-medium px-4 py-2";
  }

  return (
    <button disabled={disabled} className={cx(variantClass, sizeClass, className, disabled && "opacity-30 cursor-not-allowed")} {...props}>
      {children}
    </button>
  );
};

export default Button;
