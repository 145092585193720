import { useRef, useMemo, useEffect } from "react";
import { useSelectState } from "@react-stately/select";
import { Item } from "@react-stately/collections";
import { useSelect, HiddenSelect } from "@react-aria/select";
import { useButton } from "@react-aria/button";
import { useDetectClickOutside } from "react-detect-click-outside";
import { TiArrowSortedDown } from "react-icons/ti";
import cx from "classnames";
import { ListBox } from "../list-box";
import { useFormContext } from "react-hook-form";
import classes from "./select.module.css";

const Select = ({ name, options, ...props }) => {
  const { watch, register, setValue, formState: { errors } } = useFormContext();

  const { onBlur, ref } = useMemo(() => {
    return register(name, { required: props?.required || false });
  }, [name, register]);
  const innerRef = useRef(null);

  const value = watch(name);

  const children = useMemo(
    () =>
      options.map(({ value, label }) => (
        <Item key={value} value={value}>
          {label}
        </Item>
      )),
    [options]
  );

  const state = useSelectState({
    ...props,
    children,
    onSelectionChange: (value) => {
      setValue(name, value);
    },
    selectedKey: value || null,
  });

  const clickOutsideRef = useDetectClickOutside({ onTriggered: state.close });

  let { triggerProps, valueProps, menuProps } = useSelect(
    {
      ...props,
      onBlur: (e) => {
        onBlur(e);
        state.close();
      },
    },
    state,
    innerRef
  );

  let { buttonProps } = useButton(triggerProps, innerRef);

  return (
    <div className={"w-full relative text-xs lg:text-lg"} ref={clickOutsideRef}>
      <button
        autoFocus={props.autoFocus}
        className="w-full flex justify-between items-center py-2 px-4 border-gray-700 border-solid"
        style={{ borderWidth: 1 }}
        {...buttonProps}
        ref={(e) => {
          innerRef.current = e;
          ref(e);
        }}
      >
        <span {...valueProps}>
          {state.selectedItem ? (
            state.selectedItem.rendered
          ) : (
            <span className="dark:text-white">{props.label}</span>
          )}
        </span>
        <span aria-hidden="true">
          <TiArrowSortedDown />
        </span>
      </button>
      {state.isOpen && (
        <div
          className={cx(
            classes.listWrapper,
            "absolute left-0 w-full bg-gray-100"
          )}
        >
          <ListBox {...menuProps} state={state} />
        </div>
      )}
      <HiddenSelect
        state={state}
        triggerRef={innerRef}
        label={props.label}
        name={name}
      />
      {errors[name]?.type === 'required' && <span className="text-danger text-xs">This field is required.</span>}
    </div>
  );
};

export default Select;
