export const SET_ROLE = 'SET_ROLE';
export const SET_ID = 'SET_ID';

export const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_ROLE:
            return {
                ...state,
                role: payload,
            }
        case SET_ID:
            return {
                ...state,
                id: payload,
            }
        default: {
            throw new Error(`Unhandled action type: ${type}`)
        }
    }
}