import { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import _ from "lodash";
import { useUser } from "../../../context/user";
import { getWelcomeSurvey } from "../../../api/user";
import { postWelcomeSurvey } from "../../../api/survey";
import { useIntl } from "react-intl";

export const context = createContext();

export const useRequiredInquireData = () => useContext(context);

function reducer(state, action) {
  switch (action.type) {
    case "NEXT":
      if (state.currentStep < state.steps.length - 1) {
        return { ...state, currentStep: state.currentStep + 1 };
      }
      return state;

    case "PASS_WELCOME":
      return { ...state, isMobileWelcomePage: false };

    case "PREVIOUS":
      if (state.currentStep >= 1) {
        return { ...state, currentStep: state.currentStep - 1 };
      }
      return state;
    default:
    case "PENDING":
      return {
        ...state,
        status: "PENDING",
      }
    case "COMPLETED":
      return {
        ...state,
        status: "COMPLETED",
      }

    case "SET_FIELDS":
      return {
        ...state,
        fields: action.payload
      }
  }
}

export const RequireInquireProvider = ({ children }) => {
  // TODO make a proper hook for this
  const intl = useIntl();
  const isMobile = window.matchMedia("(max-width: 1023px)").matches;
  const { state: user } = useUser();
  const [state, dispatch] = useReducer(reducer, {
    currentStep: 0,
    steps: [1, 2, 3, 4],
    isMobileWelcomePage: isMobile,
    isMobile: isMobile,
    status: 'WAITING',
    fields: null,
  });
  const form = useForm();

  useEffect(() => {
    dispatch({ type: 'SET_FIELDS', payload: getWelcomeSurvey() });
  }, []);

  const onSubmit = useCallback(async () => {
    const values = form.getValues();

    if (state.currentStep === state.steps.length - 1) {

      dispatch({ type: 'PENDING' });

      try {
        await postWelcomeSurvey({
          userId: user.id,
          timestamp: new Date().toString(),
          sections: state.fields.sections.map(({ code, description, questions }) => {
            return {
              code,
              description,
              questions: questions.map(({ code, type }) => ({
                code,
                answer: values[code],
                type,
                label: intl.formatMessage({
                  id: `${code}`
                })
              }))
            }
          }),
        });
        dispatch({ type: "COMPLETED" });
      } catch (err) {
        console.log(err);
      }
    } else {
      // TODO: VALIDATE
      dispatch({ type: "NEXT" });
    }
  }, [state.currentStep, state.steps.length, form, user.userId]);

  return (
    <context.Provider
      value={{
        state: {
          ...state,
          hasPrevious: state.currentStep >= 1,
          hasNext: state.currentStep < state.steps.length - 1,
        },
        dispatch,
        form,
        onSubmit: form.handleSubmit(onSubmit),
      }}
    >
      {children}
    </context.Provider>
  );
};
