import cx from "classnames";
import { useRadioGroup, useRadio } from "@react-aria/radio";
import { useRadioGroupState } from "@react-stately/radio";
import { createContext, forwardRef, useContext, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

let RadioContext = createContext(null);

const Input = (props) => {
  const state = useContext(RadioContext);
  const ref = useRef(null);
  const { inputProps } = useRadio(props, state, ref);
  const [isFocus, setFocus] = useState(false);
  const onFocus = (e) => {
    setFocus(true);
    inputProps?.onFocus(e);
  };

  const onBlur = (e) => {
    setFocus(false);
    inputProps?.onFocus(e);
  };

  return (
    <label
      className={cx(
        "cursor-pointer flex items-center justify-center w-7 h-7 border rounded-full text-center align-middle relative dark:hover:bg-yellow-800 dark:hover:border-transparent",
        inputProps.checked &&
        !isFocus &&
        "dark:bg-yellow-900 dark:border-yellow-900",
        isFocus && "dark:white dark:bg-yellow-900"
      )}
    >
      <span className="block">{props.children}</span>
      <input
        {...inputProps}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
        className="cursor-pointer opacity-0 absolute"
      />
    </label>
  );
};

const Classify = forwardRef(({ name, required, options, ...props }) => {
  const { label } = props;
  const { watch, setValue, register, formState: { errors } } = useFormContext();
  const value = watch(name);

  let state = useRadioGroupState({
    name,
    label: props.label,
    onChange: (value) => {
      setValue(name, value);
    },
    value: value || null,
  });
  let { radioGroupProps, labelProps } = useRadioGroup(props, state);

  return (
    <div
      {...radioGroupProps}
      className="grid grid-flow-row auto-rows-min gap-4"
    >
      <span {...labelProps} className="text-lg font-medium">
        {label}
      </span>

      <div>
        <div className="flex justify-between pb-1">
          <span className="text-xs">{props.before}</span>
          <span className="text-xs">{props.after}</span>
        </div>
        <div className="flex justify-between">
          <RadioContext.Provider value={state}>
            {Array.from({ length: props.to }).map((_, index) => (
              <Input
                key={index + props.from}
                value={(index + props.from).toString()}
                name={`${name}-${index}`}
              >
                {index + props.from}
              </Input>
            ))}
          </RadioContext.Provider>
          <input type="hidden" name={name} value={value}  {...register(name, { required })} />
        </div>
      </div>
      {errors[name]?.type === 'required' && <span className="text-danger text-xs">This field is required.</span>}
    </div>
  );
});

export default Classify;
